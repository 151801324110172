<template>
  <div id="app">
    <router-view />
       <app-scroll></app-scroll>
       <footer>
    <cookie-law theme="dark-lime--rounded"></cookie-law>
  </footer>
  </div>
</template>

<script>
import scroll from "../src/components/scrolltotop.vue";
import CookieLaw from 'vue-cookie-law'
export default {
  name: "App",
  components: {
    "app-scroll": scroll, CookieLaw
  },
};
</script>

<style lang="scss" scoped></style>
